<template>
  <div :class="['keyboard', keyboardClass]">
    <div
      v-for="(row, rowIndex) in displayedLayout[layout]"
      :key="`row-${rowIndex}`"
      class="row"
    >
      <div
        v-for="key in row"
        :key="key.label"
        :class="['item', key.classes || '']"
        @click="handleClick(key)"
      >
        {{ key.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseKeyboardComponent",
  props: {
    keyLayout: {
      type: Object,
      required: true,
    },
    keyboardClass: {
      type: String,
      default: "",
    },
  },
  emits: ["addToInput", "removeLastChar", "submit"],
  data() {
    return {
      layout: "letters",
      isShifted: false,
    };
  },
  computed: {
    displayedLayout() {
      const newLetters = this.keyLayout.letters.map((row) =>
        row.map((key) => ({
          ...key,
          label:
            key.action === "shift"
              ? key.label
              : this.isShifted
              ? key.label.toUpperCase()
              : key.label.toLowerCase(),
        }))
      );

      return {
        letters: newLetters,
        symbols: this.keyLayout.symbols,
      };
    },
  },

  mounted() {
    this.updateKeysCase();
  },
  methods: {
    toggleShift() {
      this.isShifted = !this.isShifted;
      this.updateKeysCase();
    },
    updateKeysCase() {
      this.keyLayout.letters.forEach((row) => {
        row.forEach((key) => {
          if (key.label && !key.action) {
            key.label = this.isShifted
              ? key.label.toUpperCase()
              : key.label.toLowerCase();
          }
        });
      });
    },
    handleClick(key) {
      if (key.action === "remove") {
        this.$emit("removeLastChar");
      } else if (key.action === "toggle") {
        this.layout = this.layout === "letters" ? "symbols" : "letters";
      } else if (key.action === "shift") {
        this.toggleShift();
      } else if (key.action === "space") {
        this.$emit("addToInput", " ");
      } else {
        this.$emit("addToInput", key.label);
      }
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style scoped>
.small-keyboard {
  margin-left: 3vw;
}
.row {
  display: flex;
  gap: 0.5vw;
  width: 100%;
  margin-bottom: 0.5vw;
}
.item {
  background: rgba(217, 217, 217, 0.5);
  height: 4vw;
  width: 4vw;
  border-radius: 1vw;
  font-family: "Roboto Bold";
  font-size: 2vw;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 20px 0 rgba(83, 83, 83, 0.25);
  transition: transform 0.2s ease;
}
.item:hover,
.item:active {
  background: rgba(217, 217, 217, 0.8);
  cursor: pointer;
  transform: scale(1.1);
  animation: hoverEffect 1.5s ease-out;
}

@keyframes hoverEffect {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.item-small {
  font-size: 1.5vw;
}

.btn {
  background-color: #68aef9;
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1.25vw;
  letter-spacing: -0.075vw;
  padding: 0.5vw 1vw;
  border-radius: 3vw;
  margin-left: 1vw;
  width: 10vw;
  height: 1.5vw;
  border: none;
  margin: auto;
  cursor: pointer;
  text-align: center;
  line-height: 1.5vw;
  transition: transform 0.2s;
  margin-bottom: 0.5vw;
}
.btn:hover {
  transform: scale(1.05);
}
.btn:focus {
  outline: none;
}

.keyboard .double-col {
  width: 8.5vw !important;
}

.small-keyboard .double-col {
  width: 7.5vw !important;
}
</style>
