import { createStore } from "vuex";

import { actions } from "./actions";
import { getters } from "./getters";

const store = createStore({
  state() {
    return {
      data: {
        gender: "",
        phone: "",
        phoneDialCode: "",
        authPage: "register",
        sms_code: ["", "", "", ""],
        sms_code_timeout: false,
        email_code: ["", "", "", ""],
        email_code_timeout: false,
        images: {
          scan: {
            frontImage: null,
            backImage: null,
          },
          page1: {
            infrared: "",
            ultraViolet: "",
            visible: "",
          },
          page2: null,
          face: {
            visible: "",
          },
        },
        currentJob: {
          name: "",
        },
        email: "",
        selectedPartners: {},
      },
      keyboard: {
        show: false,
        value: "",
        inputKey: "",
      },
      partners: [],
      partner_categories: [],
      countries: [
        {
          image: require("@/assets/img/lang/de.webp"),
          name: "Deutsch",
          code: "DE",
        },
        {
          image: require("@/assets/img/lang/en.svg"),
          name: "English",
          code: "EN",
        },
        {
          image: require("@/assets/img/lang/es.jpg"),
          name: "Español",
          code: "ES",
        },
        {
          image: require("@/assets/img/lang/fr.jpg"),
          name: "Français",
          code: "FR",
        },
        {
          image: require("@/assets/img/lang/tr.webp"),
          name: "Türkçe",
          code: "TR",
        },
      ],
      idCheckData: {
        firstname: "",
        birthplace: "",
        id_number: "",

        lastname: "",
        gender: "",
        id_expiry: "",

        birth_name: "",
        nationality: "",
        id_issuer: "",

        birthdate: "",
        document_type: "",
      },
      idCheckStatus: {
        frontImage: {
          error: false,
          warning: false,
          message: "",
        },
        backImage: {
          error: false,
          warning: false,
          message: "",
        },
      },
      addressCheckData: {
        zip_code: "",
        city: "",
        street: "",
        house_number: "",
      },
      jobs: [
        {
          name: "employee",
        },
        {
          name: "self_employed",
        },
        {
          name: "student",
        },
        {
          name: "unemployed",
        },
        {
          name: "retired",
        },
        {
          name: "other",
        },
      ],
      templateMails: [
        {
          mail: "@gmail.com",
        },
        {
          mail: "@web.de",
        },
        {
          mail: "@gmx.de",
        },
        {
          mail: "@outlook.com",
        },
        {
          mail: "@hotmail.com",
        },
      ],
    };
  },
  mutations: {
    SET_PARTNERS(state, partners) {
      state.partners = partners;
    },
    SET_PARTNER_CATEGORIES(state, partners) {
      state.partner_categories = partners;
    },
    SET_SMS_CODE_TIMEOUT(state, payload) {
      state.data.sms_code_timeout = payload;
    },
    SET_EMAIL_CODE_TIMEOUT(state, payload) {
      state.data.email_code_timeout = payload;
    },
    SET_KEYBOARD_VALUE(state, payload) {
      state.keyboard.value = payload;
    },
    SET_KEYBOARD_INPUT_KEY(state, payload) {
      state.keyboard.inputKey = payload;
    },
    SET_KEYBOARD(state, payload) {
      state.keyboard.show = payload;
    },
    SET_ADDRESS_CHECK_DATA(state, payload) {
      state.addressCheckData[payload.key] = payload.value;
    },
    TOGGLE_SELECTED_PARTNER(state, payload) {
      const partner = state.data.selectedPartners[payload.name];
      if (partner) {
        delete state.data.selectedPartners[payload.name];
      } else {
        state.data.selectedPartners[payload.name] = payload;
      }
    },
    SET_EMAIL(state, payload) {
      state.data.email = payload;
    },
    setCurrentJob(state, payload) {
      state.data.currentJob = payload;
    },
    setGender(state, payload) {
      state.data.gender = payload;
    },
    setPhone(state, payload) {
      if (payload[0] === "0") {
        payload = payload.slice(1);
      }
      state.data.phone = payload;
    },
    setPhoneDialCode(state, payload) {
      state.data.phoneDialCode = payload;
    },
    SET_SCAN_FRONT_IMAGE(state, image) {
      state.data.images.scan.frontImage = image;
    },
    SET_SCAN_BACK_IMAGE(state, image) {
      state.data.images.scan.backImage = image;
    },
    CLEAR_DATA(state) {
      state.idCheckData = {
        firstname: "",
        birthplace: "",
        id_number: "",

        lastname: "",
        gender: "",
        id_expiry: "",

        birth_name: "",
        nationality: "",
        id_issuer: "",

        birthdate: "",
        document_type: "",
      };

      (state.addressCheckData = {
        zip_code: "",
        city: "",
        street: "",
        house_number: "",
      }),
        (state.data = {
          gender: "",
          phone: "",
          phoneDialCode: "",
          authPage: "register",
          sms_code: ["", "", "", ""],
          sms_code_timeout: false,
          email_code_timeout: false,
          email_code: ["1", "2", "3", "4"],
          images: {
            scan: {
              frontImage: null,
              backImage: null,
            },
            page1: {
              infrared: "",
              ultraViolet: "",
              visible: "",
            },
            page2: null,
            face: {
              visible: "",
            },
          },
          currentJob: {
            name: "",
          },
          email: "",
          selectedPartners: {},
        });
    },
    SET_AUTH_PAGE(state, payload) {
      state.data.authPage = payload;
    },
    UPDATE_SCANNER_IMAGES(state, { images, data }) {
      if (images.face) {
        state.data.images.face = images.face;
      }

      if (data.document.singleSided) {
        state.data.images.page1 = images.document;

        console.log("UPDATE_SCANNER_IMAGES", state.data.images);
      } else {
        if (data.document.page == 1) {
          state.data.images.page1 = images.document;
        }

        if (data.document.page == 2) {
          state.data.images.page2 = images.document;
        }
      }

      console.log("IMAGES", state.data.images);
    },
    CLEAR_ADDRESS_DATA(state) {
      console.log("CLEAR_ADDRESS_DATA");
      state.addressCheckData = {
        zip_code: "",
        city: "",
        street: "",
        house_number: "",
      };
    },
    SET_ADDRESS_DATA(state, addressData) {
      console.log("SET_ADDRESS_DATA", addressData);
      state.addressCheckData = {
        zip_code: addressData.zip_code || "",
        city: addressData.city || "",
        street: addressData.street || "",
        house_number: addressData.house_number || "",
      };
    },
    UPDATE_ID_CHECK_DATA(state, payload) {
      state.idCheckData = {
        ...state.idCheckData,
        ...payload,
      };
    },
    UPDATE_FRONT_IMAGE_STATUS(state, { warning, error, message }) {
      state.idCheckStatus.frontImage.warning = warning || false;
      state.idCheckStatus.frontImage.error = error || false;
      state.idCheckStatus.frontImage.message = message || "";
    },
    UPDATE_BACK_IMAGE_STATUS(state, { warning, error, message }) {
      state.idCheckStatus.backImage.warning = warning || false;
      state.idCheckStatus.backImage.error = error || false;
      state.idCheckStatus.backImage.message = message || "";
    },
  },
  actions: actions,
  getters: getters,
});

export default store;
