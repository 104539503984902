<template>
  <div class="welcome-page">
    <partner-component></partner-component>
    <div class="content">
      <div class="header">
        <div class="d-block">
          <h2>{{ $t("welcome") }}</h2>
          <img src="@/assets/logo.png" class="logo" alt="logo" />
        </div>

        <div class="auth-section">
          <div class="d-flex auth">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              fill="none"
              viewBox="0 0 27 27"
            >
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                d="M22.5 23.625c0-3.107-4.03-5.625-9-5.625s-9 2.518-9 5.625m9-9a5.625 5.625 0 1 1 0-11.25 5.625 5.625 0 0 1 0 11.25Z"
              />
            </svg>

            <p>{{ $t("already_customer") }}</p>
          </div>
          <div class="btn login-now" @click="login()">{{ $t("login") }}</div>
        </div>
      </div>

      <div class="partner">
        <div class="partner-text">{{ $t("one_account") }}</div>

        <div class="partner-images">
          <div
            v-for="item in partners"
            :key="item.image"
            :class="[{ 'partner-image': true }, { 'z-1': item.user }]"
          >
            <img :src="item.image" alt="partner" />
          </div>
        </div>

        <div class="partner-footer">{{ $t("register_time") }}</div>

        <div class="btn register-now" @click="register()">{{ $t("register_now") }}</div>
      </div>

      <language-component></language-component>
    </div>
  </div>
</template>

<script>
import PartnerComponent from "../components/PartnerComponent.vue";
import LanguageComponent from "../components/LanguageComponent.vue";

export default {
  name: "WelcomeComponent",
  components: {
    PartnerComponent,
    LanguageComponent,
  },
  data() {
    return {};
  },
  computed: {
    partners() {
      const partners = this.$store.getters.partners;
      partners.sort((a, b) => a.id - b.id);

      const modifiedPartners = [
        partners[0],
        {
          image: require("@/assets/img/partner/user-tick.svg"),
          user: true,
        },
        ...partners.slice(1, 8),
      ];

      return modifiedPartners;
    },
  },
  methods: {
    login() {
      this.$store.commit("SET_AUTH_PAGE", "login");
      this.$router.push("/phone");
    },
    register() {
      this.$store.commit("SET_AUTH_PAGE", "register");
      this.$router.push("/gender");
    },
  },
  mounted() {
    // clear data
    this.$store.commit("CLEAR_DATA");
  },
  created() {
    this.$store.dispatch("loadPartners");
  },
};
</script>

<style scoped>
.welcome-page {
  display: flex;
}
.content {
  margin: 2vw 0 0 1.5vw;
  width: 100%;
}
h2 {
  font-size: 1.15vw;
  font-family: "Roboto Bold";
  opacity: 0.4;
  margin: 0;
  color: #fff;
}
h1 {
  color: #fff;
  font-family: "Roboto Bold";
  margin: 0;
  font-size: 1.85vw;
}
.d-flex {
  display: flex;
  align-items: center;
}
.d-block {
  display: block;
}
.auth-section {
  position: absolute;
  top: 0;
  right: -1.5vw;
}
.auth svg {
  width: 1vw;
  margin-right: 0.5vw;
}
.auth p {
  font-family: "Roboto Bold";
  font-size: 1vw;
  color: #fff;
}
.header {
  display: flex;
}
.btn {
  background: #68aef9;
  color: #fff;
  font-family: "Roboto Bold";

  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2vw;
  border-radius: 1.5vw;
  margin: auto;
  font-size: 1vw;
  box-shadow: 0 5px 5px 0 #6e6e6e;
  letter-spacing: -0.075vw;
}
.btn:hover {
  cursor: pointer;
  transition: 0.3s;
  background: #5a9be6;
}

.register-now {
  position: relative;
  bottom: 10vw;
  width: 55%;
  height: 4vw;
  font-size: 1.4vw;
  border-radius: 3vw;
}

.partner {
  background: rgba(217, 217, 217, 0.5);
  width: 30vw;
  height: 25vw;
  margin: auto;
  margin-top: 3vw;
  border-radius: 3vw;
  box-shadow: 0 10px 20px 0 #535353;
}
.partner::before {
  content: "";
  position: fixed;
  width: 30vw;
  height: 25vw;
  background: rgba(255, 255, 255, 0.36);
  border-radius: 3vw;
  z-index: -1;
}
.partner-text {
  font-family: "Roboto Bold";
  font-size: 1.5vw;
  text-align: center;
  color: #585858;
  padding-top: 1vw;
}
.partner-footer {
  font-family: "Roboto Bold";
  font-size: 1.25vw;
  text-align: center;
  padding-top: 1vw;
  letter-spacing: -0.075vw;
  color: #fff;
}
.partner-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  margin-top: 2vw;
}
.partner-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vw;
  width: 8vw;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 1.5vw;
  z-index: -1;
}
.partner-image img {
  width: 4vw;
}
.login-now {
  margin-top: -1vh;
}
.z-1 {
  z-index: 1;
}

.logo {
  height: 3vw;
  margin-left: -0.5vw;
}
</style>
