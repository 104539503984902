import api from "../../api";
import i18n from "../../locale";
import helpers, { getSettings } from "../../helpers";

export const actions = {
  async loadPartners({ commit }) {
    const response = await api.get(process.env.VUE_APP_API_URL + "/partners");
    const data = response.data;

    const partnersWithImages = data.map((partner) => {
      return {
        ...partner,
        image: `https://prism-id.de/storage/${partner.logo}`,
      };
    });

    const categories = ["All", ...new Set(partnersWithImages.map(partner => partner.category))].map(category => ({
      name: category,
      selected: category === "All"
    }));
  
    commit("SET_PARTNER_CATEGORIES", [...new Set(categories)]);
    commit("SET_PARTNERS", partnersWithImages);
  },
  updateIdCheckData({ commit, state }, data) {
    const images = data?.images;

    if (images) {
      commit("UPDATE_SCANNER_IMAGES", { data, images });
    }

    if (data?.ocr) {
      // Update address details
      if (data.ocr?.address) {
        const addressData = helpers.parseAddress(data.ocr?.address);
        commit("SET_ADDRESS_DATA", addressData);
      }

      if (data.ocr?.birthName) {
        // Commit ID check details
        commit("UPDATE_ID_CHECK_DATA", {
          birth_name: data.ocr?.birthName,
        });
      }

      if (data.ocr?.birthPlace) {
        commit("UPDATE_ID_CHECK_DATA", {
          birthplace: data.ocr?.birthPlace,
        });
      }
    }

    if (data?.mrz) {
      commit("UPDATE_ID_CHECK_DATA", {
        firstname: data.mrz?.firstname || i18n.global.t("id_check.not_found"),
        lastname: data.mrz?.lastname || i18n.global.t("id_check.not_found"),
        birthdate:
          helpers.formatDate(data.mrz?.birthdate) ||
          i18n.global.t("id_check.not_found"),
        gender: data.mrz?.sex || state.data.gender || i18n.global.t("id_check.not_found"),
        nationality:
          data.mrz?.nationality || i18n.global.t("id_check.not_found"),
        document_type:
          data.mrz?.document?.code || i18n.global.t("id_check.not_found"),
        id_number:
          data.mrz?.document?.number || i18n.global.t("id_check.not_found"),
        id_expiry:
          helpers.formatDate(data.mrz?.document?.expiryDate) ||
          i18n.global.t("id_check.not_found"),
        id_issuer:
          data.mrz?.document?.issuer || i18n.global.t("id_check.not_found"),
      });
    }
  },

  checkIdCard({ dispatch, commit }, data) {
    if (data.document.page == 1) {
      // Ausweis Vorderseite
      if (data.document.fuzzy) {
        commit("UPDATE_FRONT_IMAGE_STATUS", {
          warning: true,
          message: i18n.global.t("id_check.fuzzy"),
        });
        dispatch(
          "updateScanFrontImage",
          "data:image/jpeg;base64," + data.images.document.infrared
        );
        return;
      }

      if (!data.document.valid) {
        commit("UPDATE_FRONT_IMAGE_STATUS", {
          error: true,
          message: i18n.global.t("id_check.invalid_document"),
        });
        return;
      }

      commit("UPDATE_FRONT_IMAGE_STATUS", {
        warning: false,
        error: false,
        message: "",
      });

      dispatch(
        "updateScanFrontImage",
        "data:image/jpeg;base64," + data.images.document.infrared
      );

      dispatch("updateIdCheckData", data);
    } else if (data.document.page == 2) {
      // Ausweis Rückseite
      if (data.mrz && !data.mrz.valid) {
        commit("UPDATE_BACK_IMAGE_STATUS", {
          error: true,
          message: i18n.global.t("id_check.mrz_invalid"),
        });
        return;
      }

      if (data.document.fuzzy) {
        commit("UPDATE_BACK_IMAGE_STATUS", {
          warning: true,
          message: i18n.global.t("id_check.fuzzy"),
        });

        dispatch(
          "updateScanBackImage",
          "data:image/jpeg;base64," + data.images.document.infrared
        );
        return;
      }

      if (!data.document.valid) {
        commit("UPDATE_BACK_IMAGE_STATUS", {
          error: true,
          message: i18n.global.t("id_check.invalid_document"),
        });
        return;
      }

      const birthdateValidator = helpers.validateBirthdate(data.mrz.birthdate);

      if (birthdateValidator.error) {
        commit("UPDATE_BACK_IMAGE_STATUS", {
          error: true,
          message: birthdateValidator.message,
        });
      }

      const expirydateValidator = helpers.validateExpiryDate(
        data.mrz.id_expiry
      );

      if (expirydateValidator.error) {
        commit("UPDATE_BACK_IMAGE_STATUS", {
          error: true,
          message: expirydateValidator.message,
        });
      }

      commit("UPDATE_BACK_IMAGE_STATUS", {
        warning: false,
        error: false,
        message: "",
      });

      dispatch(
        "updateScanBackImage",
        "data:image/jpeg;base64," + data.images.document.infrared
      );

      dispatch("updateIdCheckData", data);
    }
  },
  idcheckDone({ commit, state }) {
    // check for birtName and birthPlace set
    if (!state.idCheckData.birth_name) {
      commit("UPDATE_ID_CHECK_DATA", {
        // last name or not found
        birth_name:
          state.idCheckData.lastname || i18n.global.t("id_check.not_found"),
      });
    }
  },
  checkPassport({ dispatch, commit }, data) {
    if (data.document.fuzzy) {
      commit("UPDATE_FRONT_IMAGE_STATUS", {
        warning: true,
        message: i18n.global.t("id_check.fuzzy"),
      });
      return;
    }

    if (!data.document.valid) {
      commit("UPDATE_FRONT_IMAGE_STATUS", {
        error: true,
        message: i18n.global.t("id_check.invalid_document"),
      });
      return;
    }

    const birthdateValidator = helpers.validateBirthdate(data.mrz.birthdate);

    if (birthdateValidator.error) {
      commit("UPDATE_FRONT_IMAGE_STATUS", {
        error: true,
        message: birthdateValidator.message,
      });
    }

    const expirydateValidator = helpers.validateExpiryDate(data.mrz.id_expiry);

    if (expirydateValidator.error) {
      commit("UPDATE_FRONT_IMAGE_STATUS", {
        error: true,
        message: expirydateValidator.message,
      });
    }

    commit("UPDATE_FRONT_IMAGE_STATUS", {
      warning: false,
      error: false,
      message: "",
    });

    commit("CLEAR_ADDRESS_DATA")

    dispatch(
      "updateScanFrontImage",
      "data:image/jpeg;base64," + data.images.document.infrared
    );

    dispatch(
      "updateScanBackImage",
      "data:image/jpeg;base64," + data.images.document.infrared
    );

    dispatch("updateIdCheckData", data);
  },
  startSmsCodeTimeout({ commit }) {
    setTimeout(() => {
      commit("SET_SMS_CODE_TIMEOUT", false);
    }, 30000);
  },
  startEmailCodeTimeout({ commit }) {
    setTimeout(() => {
      commit("SET_EMAIL_CODE_TIMEOUT", false);
    }, 30000);
  },
  async sendSmsCode({ state }) {
    if (process.env.VUE_APP_MODE == "development") {
      return true;
    }
    const phone = state.data.phoneDialCode + state.data.phone;
    console.log("Sending SMS code to", phone);

    const settings = await getSettings();
    console.log("settings.otpEnabled", settings.otpEnabled);
    if (!settings.otpEnabled) {
      return true;
    }

    if (!phone) {
      console.error("Phone number is not set");
      return;
    }
    console.log(process.env.VUE_APP_API_URL + "/sendOTP");
    api
      .post("/sendOTP", {
        sms: phone,
      })
      .then((response) => {
        if (!response.status === 200) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async checkSmsCode({ state }, inputCode) {
    if (process.env.VUE_APP_MODE == "development") {
      return true;
    }
    const settings = await getSettings();
    console.log("settings.otpEnabled", settings.otpEnabled);
    if (!settings.otpEnabled) {
      return true;
    }
    const phone = state.data.phoneDialCode + state.data.phone;
    console.log("phone", phone);
    let code = inputCode.join("");
    code = parseInt(code);

    const response = await api
      .post("/verifyOTP", {
        sms: phone,
        otp: code,
      })
      .catch((error) => {
        console.log(error);
      });

    if (!response || response.status != 200) {
      return false;
    }
    return response.data.isOTPValid || false;
  },
  async sendEmailCode({ state }) {
    if (process.env.VUE_APP_MODE == "development") {
      return true;
    }
    const settings = await getSettings();
    console.log("settings.otpEnabled", settings.otpEnabled);
    if (!settings.otpEnabled) {
      return true;
    }

    if (!state.data.email) {
      console.error("Email is not set");
      return;
    }

    api
      .post("/sendOTP", {
        email: state.data.email,
      })
      .then((response) => {
        if (!response.status === 200) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async checkEmailCode({ state }, inputCode) {
    if (process.env.VUE_APP_MODE == "development") {
      return true;
    }
    const settings = await getSettings();
    console.log("settings.otpEnabled", settings.otpEnabled);
    if (!settings.otpEnabled) {
      return true;
    }
    let code = inputCode.join("");
    code = parseInt(code);

    const response = await api
      .post("/verifyOTP", {
        email: state.data.email,
        otp: code,
      })
      .catch((error) => {
        console.log(error);
      });

    if (!response || response.status != 200) {
      return false;
    }

    return response.data.isOTPValid || false;
  },
  async compareFaces({ state }, { compareImage }) {
    if (process.env.VUE_APP_MODE == "development") {
      return true;
    }
    // remove data:image/jpeg;base64, from the image
    let cameraImage = compareImage;
    let documentImage = state.data.images.face.visible;

    console.log("cameraImage", cameraImage);
    console.log("documentImage", documentImage);

    const response = await api
      .post("/compareFaces", {
        cameraImage: cameraImage,
        documentImage: documentImage,
      })
      .catch((error) => {
        console.log(error);
      });

    if (!response || response.status != 200) {
      return false;
    }

    console.log("compareFaces result", response.data);
    return (
      response.data.Similarity >= process.env.VUE_APP_SIMILARIY_COMPARE_FACES
    );
  },
  async register({ state, commit, getters }) {
    // get state.data.selectedPartners
    const partners = getters.getSelectedPartners;
    const phonenumber = state.data.phoneDialCode + state.data.phone;
    console.log("partners", partners);
    const registerData = {
      firstname: state.idCheckData.firstname,
      lastname: state.idCheckData.lastname,
      birthname: state.idCheckData.birth_name,
      birthdate: state.idCheckData.birthdate,
      gender: state.data.gender,
      nationality: state.idCheckData.nationality,
      birthplace: state.idCheckData.birthplace,
      email: state.data.email,
      phonenumber: phonenumber,
      document: {
        number: state.idCheckData.id_number,
        type: state.idCheckData.document_type,
        issuer: state.idCheckData.id_issuer,
        expiration: state.idCheckData.id_expiry,
      },
      address: {
        street: state.addressCheckData.street,
        number: state.addressCheckData.house_number,
        zipcode: state.addressCheckData.zip_code,
        city: state.addressCheckData.city,
        country: state.addressCheckData.nationality, // ? nicht im Scanner drin
      },
      partners: partners,
      images: {
        face: state.data.images.face.visible,
        page1: {
          default: state.data.images.page1.visible,
          infrared: state.data.images.page1.infrared,
          ultraviolet: state.data.images.page1.ultraViolet,
        },
        ...(state.data.images.page2?.visible && {
          page2: {
            default: state.data.images.page2.visible,
            infrared: state.data.images.page2.infrared,
            ultraviolet: state.data.images.page2.ultraViolet,
          },
        }),
      },
    };
    console.log("registerData", registerData);

    const response = await api
      .post("/clients/register", registerData)
      .catch((error) => {
        console.log(error);
      });

    console.log("registerd", response);

    commit("CLEAR_DATA");
  },
  updateScanFrontImage({ commit }, image) {
    commit("SET_SCAN_FRONT_IMAGE", image);
  },
  updateScanBackImage({ commit }, image) {
    commit("SET_SCAN_BACK_IMAGE", image);
  },
  updateGender({ commit }, gender) {
    commit("setGender", gender);
  },
};
